<script setup lang="ts">
import type { MenuItem } from "primevue/menuitem";
import { reactive } from "vue";
import { RouterView } from "vue-router";

import AppMenu from "@/components/business/AppMenu/AppMenu.vue";
import CreateAssetsWatcher from "@/components/business/CreateAssetsWatcher/CreateAssetsWatcher.vue";
import UserMenu from "@/components/modules/UserMenu/UserMenu.vue";
import links from "@/router/links";

const items = reactive<MenuItem[]>([
  {
    label: "Home",
    icon: "fa-light fa-home",
    to: links.home,
  },
  {
    label: "Campaigns",
    icon: "fa fa-megaphone",
    to: links.campaigns.list,
  },
  {
    label: "Assets",
    icon: "fa fa-folder-image",
    to: links.assets.list,
  },
  {
    label: "Apps",
    icon: "fa-thin fa-rectangle-history",
    to: links.apps.list,
  },
]);
</script>

<template>
  <div class="root-page z-0 flex h-screen w-full flex-col overflow-hidden">
    <AppMenu :items="items">
      <template #end>
        <UserMenu />
      </template>
    </AppMenu>

    <div
      id="router-container"
      class="router-container relative flex flex-1 flex-col items-center overflow-y-auto p-2 px-8 pt-8"
    >
      <div class="flex w-full max-w-[180rem] flex-1 flex-col">
        <div class="flex flex-1 flex-col">
          <RouterView />
        </div>

        <CreateAssetsWatcher />

        <footer class="root-page-footer w-full px-0 pb-3 pt-6">
          <div class="text-color-secondary">
            Copyright @Voodoo
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
