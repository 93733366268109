<script lang="ts" setup>
import { computed } from "vue";

import DashboardWidget from "@/components/business/DashboardWidget/DashboardWidget.vue";
import VdButton from "@/components/structures/VdButton/VdButton.vue";
import VdRouterLink from "@/components/structures/VdRouterLink/VdRouterLink.vue";
import { CAMPAIGN_STATUS_ICONS, type CampaignObjective } from "@/constants";
import { CampaignStatus } from "@/graphql";
import links from "@/router/links";
import { toCampaignObjective } from "@/utils/to-campaign-objective";

import { useDashboardStatsQuery } from "../../queries/dashboard-stats.query.graphql";

const props = defineProps<{
  icon: string;
  title: string;
  campaignObjective: CampaignObjective;
}>();

const { data, isPending } = useDashboardStatsQuery();

const statsData = computed<{ [key in CampaignStatus]?: number }>(() => {
  if (data.value == null) {
    return {};
  }

  const result = {
    [CampaignStatus.Running]: 0,
    [CampaignStatus.Draft]: 0,
    [CampaignStatus.Pending]: 0,
    [CampaignStatus.Stopped]: 0,
  };

  const campaigns = (data.value.campaigns.edges ?? []).map(edge => edge?.node?.campaign).filter(Boolean);

  for (const campaign of campaigns) {
    if (props.campaignObjective === toCampaignObjective(campaign.__typename)) {
      if (campaign.status === CampaignStatus.Archived) {
        continue;
      }
      result[campaign.status] += 1;
    }
  }

  return result;
});

const stats = computed(() => Object.entries(statsData.value ?? []).map(([status, value]) => ({
  id: status,
  title: status.toLocaleLowerCase(),
  icon: CAMPAIGN_STATUS_ICONS[status as CampaignStatus],
  value,
})));
</script>

<template>
  <DashboardWidget :loading="isPending">
    <template #title-start>
      <div class="flex items-center gap-3 text-xl font-bold">
        <i :class="icon" />

        <span>{{ title }}</span>
      </div>
    </template>

    <div class="flex w-full flex-col gap-4">
      <div class="flex w-full flex-wrap justify-between gap-3">
        <div class="flex w-full flex-wrap items-center justify-between gap-1">
          <div class="flex flex-wrap items-center gap-8">
            <div v-for="statLine in stats" :key="statLine.id" class="min-w-1/6 min-w-12 sm:w-1/6 sm:min-w-24 lg:min-w-8">
              <VdRouterLink
                :to="{
                  name: links.campaigns.listByObjective,
                  params: {
                    campaignObjective,
                  },
                  query: {
                    status: statLine.id,
                  },
                }"
                class="flex flex-col items-center"
              >
                <div class="flex items-center gap-1 text-xs font-normal">
                  <i :class="statLine.icon" />
                  <span class="text-base capitalize text-gray-500">{{ statLine.title }}</span>
                </div>

                <span class="text-lg font-bold text-gray-900">{{ statLine.value }}</span>
              </VdRouterLink>
            </div>
          </div>

          <div class="ml-auto">
            <VdRouterLink
              v-if="campaignObjective !== 'consideration'"
              :to="{
                name: links.campaigns.new,
                params: {
                  campaignObjective,
                },
              }"
            >
              <VdButton
                outlined
                responsive-icon
                icon="fa-light fa-plus"
                label="New"
              />
            </VdRouterLink>
            <VdButton
              v-else
              outlined
              responsive-icon
              icon="fa-light fa-plus"
              label="New"
              disabled
              severity="secondary"
            />
          </div>
        </div>
      </div>
    </div>
  </DashboardWidget>
</template>
