import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query DashboardStats {
    campaigns {
      edges {
        node {
          campaign {
            __typename
            ... on BrandingCampaign {
              status
            }
            ... on PerformanceCampaign {
              status
            }
            ... on ConsiderationCampaign {
              status
            }
          }
        }
      }
    }
  }
`);

function getQueryOptions() {
  return {
    queryKey: buildQueryKey(query),
    async queryFn() {
      return runGraphqlQuery(query);
    },
  };
}

export function useDashboardStatsQuery() {
  return buildQuery({
    ...getQueryOptions(),
  });
}
