import { useStorage } from "@vueuse/core";
import { computed, type MaybeRef, ref, type Ref, toValue, watch } from "vue";

type Column = {
  id: string;
  label: string;
  displayedByDefault?: boolean;
};

export default function usePersistentColumns<T extends Column>(
  storageKey: string,
  columns: MaybeRef<T[]>,
): { selectedColumns: Ref<T[]> } {
  const allColumns = computed(() => toValue(columns));

  const defaultColumns = ref<Record<string, number>>({});

  const columnPositionsById = useStorage<Record<string, number>>(storageKey, () => defaultColumns.value);

  const allColumnIds = computed(() => new Set(allColumns.value.map(({ id }) => id)));
  const storedColumnsIds = computed(() => new Set(Object.keys(columnPositionsById.value)));

  watch(allColumnIds, () => {
    defaultColumns.value = Object.fromEntries(allColumns.value.map(currentValue => [currentValue.id, 0]));

    /* if there are columns in the current columns that are not in the storage, add them */
    for (const columnId of allColumnIds.value) {
      if (!storedColumnsIds.value.has(columnId)) {
        columnPositionsById.value[columnId] = 0;
      }
    }
    /* if there are columns in the storage that are not in the current columns, remove them */
    for (const columnId of storedColumnsIds.value) {
      if (!allColumnIds.value.has(columnId)) {
        delete columnPositionsById.value[columnId];
      }
    }
  }, { immediate: true });

  const selectedColumns = computed({
    get: () => {
      /** return all stored columns ordered by positions removing the hidden ones */
      return allColumns.value
        .map((column) => {
          const position = columnPositionsById.value[column.id] ?? -1;
          return { ...column, position };
        })
        .filter(column => column.position !== -1)
        .sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
    },
    set: (selection) => {
      /** set the position of selected columns */
      for (const [index, column] of selection.entries()) {
        columnPositionsById.value[column.id] = index;
      }
      /** set the position to -1 for all hidden columns */
      const selectedColumnIds = selection.map(({ id }) => id);
      const hiddenColumnIds = [...allColumnIds.value.values()].filter(o => !selectedColumnIds.includes(o));
      for (const columnId of hiddenColumnIds) {
        columnPositionsById.value[columnId] = -1;
      }
    },
  });

  return { selectedColumns };
}
