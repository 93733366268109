import { type MaybeRef, toValue } from "vue";

import type { TableColumn } from "@/components/structures/VdTable/types";
import type { ReportFragment } from "@/graphql";

import ReportingCell from "./ReportingCell.vue";

export function generateReportingColumn<T>({ column, isReportsLoading, isReportsError }: {
  column: TableColumn<Omit<ReportFragment, "__typename">>;
  isReportsLoading: MaybeRef<boolean>;
  isReportsError: MaybeRef<boolean>;
}) {
  return {
    id: column.id as keyof T,
    label: column.label,
    displayedByDefault: false,
    isSortable: true,
    component: ReportingCell,
    componentProps: ({ row }: { row: T }) => ({
      format: column.format,
      value: row[column.id as keyof T] as number,
      loading: toValue(isReportsLoading),
      inError: toValue(isReportsError),
    }),
  };
}
