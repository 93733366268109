<script lang="ts" setup>
import Button from "primevue/button";
import { computed } from "vue";

const props = defineProps<{
  responsiveIcon?: boolean;
  // eslint-disable-next-line ts/no-explicit-any
  pt?: Record<string, any> | undefined | null;
}>();

const passthrough = computed(() => {
  const labelClass = props.pt?.label?.class || "";
  return {
    label: { class: props.responsiveIcon ? `hidden md:block ${labelClass}` : labelClass },
    icon: { class: props.responsiveIcon ? "m-0 md:mr-2" : "" },
  };
});
</script>

<template>
  <Button
    class="vd-button-component"
    v-bind="$attrs"
    :pt="passthrough"
  />
</template>
