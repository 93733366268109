/**
 * Return the campaign objective from its __typename
 */

import type { CampaignObjective } from "@/constants";

import { checkForAllTypesCovered } from "./voodash/type-helpers";

export function toCampaignObjective(campaignTypeName: string): CampaignObjective | never {
  checkForAllTypesCovered<CampaignObjective>("awareness", "conversion", "consideration");

  if (campaignTypeName === "PerformanceCampaign") {
    return "conversion";
  }
  if (campaignTypeName === "BrandingCampaign") {
    return "awareness";
  }
  if (campaignTypeName === "ConsiderationCampaign") {
    return "consideration";
  }

  throw new Error(`Unknown campaign type: ${campaignTypeName}`);
}
