import type { SortingFn } from "@tanstack/vue-table";
import type { Component } from "vue";

type ComponentProps<C extends Component> = C extends new (...args: unknown[]) => unknown
  ? InstanceType<C>["$props"]
  : never;

export type TableColumn<T, C extends Component | null = null> = {
  id: keyof T;
  label: string;
  description?: string;
  format?: Intl.NumberFormatOptions;
  minWidth?: number;
  maxWidth?: number;
  displayedByDefault?: boolean;
  component: C;
  componentProps: C extends null ? null : ({ row }: { row: T }) => ComponentProps<NonNullable<C>>;
  isPinnedLeft?: boolean;
  isPinnedRight?: boolean;
  isSortable?: boolean;
  // eslint-disable-next-line ts/no-explicit-any
  sortingFn?: SortingFn<any>;
  isReporting?: boolean;
  isCentered?: boolean;
};

export function createTableColumn<T>() {
  return function<C extends Component | null = null>(column: TableColumn<T, C>): TableColumn<T, C> {
    return column;
  };
}
