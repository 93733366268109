import type { Ref } from "vue";

import type { TopSpendersQueryVariables } from "@/graphql";
import { buildQuery, buildQueryKey, graphql, runGraphqlQuery } from "@/graphql";

const query = graphql(/* GraphQL */ `
  query TopSpenders($campaignIds: [String!], $reportStartDate: String!, $reportEndDate: String!, $reportSorts: String, $first: Int) {
    campaigns(ids: $campaignIds, reportStartDate: $reportStartDate, reportEndDate: $reportEndDate, reportSorts: $reportSorts, reportType: PROBABILISTIC, first: $first) {
      edges {
        node {
          ...topSpenderCampaign
        }
      }
    }
  }
`);

graphql(/* GraphQL */ `
  fragment topSpenderCampaign on CampaignReport {
    campaignId
    campaign {
      __typename
      ... on PerformanceCampaign {
        id
        name
        type
        app {
          ...app
        }
      }
      ... on BrandingCampaign {
        id
        name
      }
      ...on ConsiderationCampaign {
        id
        name
      }
    }
    report {
      billableSpend
    }
}`);

function getQueryOptions(variables: Ref<TopSpendersQueryVariables>) {
  return {
    queryKey: buildQueryKey(query, variables),
    async queryFn() {
      return runGraphqlQuery(query, variables.value);
    },
  };
}

export function useTopSpendersQuery(variables: Ref<TopSpendersQueryVariables>, { enabled }: { enabled: Ref<boolean> }) {
  return buildQuery({
    ...getQueryOptions(variables),
    enabled,
  });
}
