<script setup lang="ts">
import AvatarGroup from "primevue/avatargroup";
import Button from "primevue/button";
import ContextMenu from "primevue/contextmenu";
import type { MenuItem } from "primevue/menuitem";
import Skeleton from "primevue/skeleton";
import { computed, onMounted, ref, watch } from "vue";

import useAuthentication from "@/composables/use-authentication";
import type { UserMenuOrganizationFragment } from "@/graphql";

import { useUserMenuQuery } from "./query.graphql";

const { user, logout, login, fetchUserInfo } = useAuthentication();
const { data } = useUserMenuQuery();

onMounted(() => {
  fetchUserInfo();
});

const menuRef = ref();
const selectedOrganizationId = ref<string>();
const selectedOrganization = computed<UserMenuOrganizationFragment | undefined>(() => {
  return data.value?.organizations.find(organization => organization.id === selectedOrganizationId.value);
});

function buildAvatarLetter(name: string) {
  return name[0]?.toUpperCase() ?? "";
}

const organizationAvatar = computed<string>(() => buildAvatarLetter(selectedOrganization.value?.name ?? ""));

watch(user, async () => {
  selectedOrganizationId.value = user.value?.organizationId;
}, { immediate: true });

function toggle(event: Event) {
  menuRef.value.toggle(event);
}

const isOrganizationSwitchLoading = ref(false);

function selectOrganization() {
  isOrganizationSwitchLoading.value = true;
  menuRef.value.hide();
}

const organizationItems = computed<MenuItem[]>(() => {
  return (data.value?.organizations ?? []).map(organization => ({
    ...organization,
    type: "organization",
    label: organization.name,
    avatar: buildAvatarLetter(organization.name ?? ""),
    command: async () => {
      await login("/", { organization: organization.id });
      selectOrganization();
    },
  } satisfies MenuItem));
});

const userMenuItems = computed<MenuItem[]>(() => [
  {
    type: "user",
    label: user.value?.email,
    icon: "fa fa-user text-sm",
    disabled: true,
  },
  {
    type: "organization",
    icon: "fa-light fa-building text-sm",
    disabled: organizationItems.value.length < 2,
    label: selectedOrganization.value?.name ?? "Select organization",
    ...(organizationItems.value.length > 1 ? { items: organizationItems.value } : {}),
  },
  { separator: true },
  {
    label: "Logout",
    icon: "fa-light fa-power-off",
    command: () => {
      logout();
    },
  },
]);
</script>

<template>
  <div class="organization-switcher-component flex items-center justify-end">
    <Button
      text
      class="w-auto p-0 pl-3"
      @click="toggle"
    >
      <AvatarGroup :pt="{ root: { class: 'flex flex-row ml-0 gap-3' } }">
        <Skeleton v-if="isOrganizationSwitchLoading" size="3rem" />
        <span v-else class="text-color-secondary text-xl">{{ organizationAvatar }}</span>
        <i class="fa-light fa-user rounded-full bg-primary-50 p-3" />
      </AvatarGroup>
    </Button>

    <ContextMenu
      ref="menuRef"
      :model="userMenuItems"
    />
  </div>
</template>
