import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { computed, type MaybeRefOrGetter, toValue, type WritableComputedRef } from "vue";

import { createObjectSerializer, type ObjectSerializerSchema } from "./object-serializer";
import { createUrlStorage, type UrlStorageValue } from "./url-storage";

export type { SchemaField } from "./object-serializer";

/**
 * This composable is responsible for managing the component state and synchronizing it
 * with the URL's query parameters. It can be used to store the current state of a component,
 * allowing users to share or bookmark URLs containing the relevant state information.
 *
 * The URL is used to initialize the state. It is then updated to reflect the state changes.
 */
export function useUrlState<T extends object>({
  schema,
  initialValue,
}: {
  schema: MaybeRefOrGetter<ObjectSerializerSchema<T>>;
  initialValue: MaybeRefOrGetter<T>;
}): WritableComputedRef<T> {
  const serializer = computed(() => createObjectSerializer<T>(toValue(schema)));

  const fields = computed(() => Object.keys(toValue(schema)) as Array<keyof T>);
  const defaultValue = computed(() => serializer.value.serialize(toValue(initialValue)));

  const urlStorage = createUrlStorage<T>(fields, defaultValue);

  /**
   * The writable computed that will handle the application state
   */
  return computed<T>({
    get() {
      const serializedObject = (urlStorage.get() as { [K in keyof T]: string | string[] | null }) ?? cloneDeep(defaultValue);
      const obj = serializer.value.deserialize(serializedObject);
      return Object.keys(toValue(initialValue)).reduce((result, valueKey) => {
        const fieldName = valueKey as unknown as keyof T;

        /**
         * If the field is null or equal to the initial value, we remove it from the URL
         *
         * If there is no value, it may mean that the value couldn't be decoded by the serializer
         * and that the user put a wrong value in the URL.
         * In this case, we just clean the URL by removing the falsy param.
         */
        return {
          ...result,
          [fieldName]: obj[fieldName] ?? toValue(initialValue)[fieldName],
        };
      }, {} as T);
    },

    set(newValue) {
      const serializedValue = serializer.value.serialize(newValue);

      /**
       * The serializedValue contains fields that can be null whereas the urlStorage.get() will
       * strip those null values. We need to remove all the null values from the serializedValue
       * in order to compare it with the urlStorage.get() value.
       */

      const cleanedValue = Object.entries(serializedValue).reduce((result, [name, val]) => ({
        ...result,
        ...(val == null ? {} : { [name]: val }),
      }), {} as typeof serializedValue);

      /**
       * If we have the same value in the URL and in the state, we don't need to update the URL.
       * Otherwise, we get a loop of updates.
       */
      if (isEqual(urlStorage.get(), cleanedValue)) {
        return;
      }

      /*
       * Reconstruct the values to store in the URL
       */
      let urlValue: UrlStorageValue = {};
      for (const [fieldName, fieldValue] of Object.entries(cleanedValue)) {
        if (fieldValue == null) {
          continue;
        }
        if (Array.isArray(fieldValue) && fieldValue.length === 0) {
          urlValue = { ...urlValue, [fieldName]: [] };
        }
        else {
          urlValue = { ...urlValue, [fieldName]: fieldValue as string };
        }
      }

      urlStorage.set(urlValue);
    },
  });
}
