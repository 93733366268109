import { type Component, computed, type MaybeRefOrGetter, toValue } from "vue";
import type { z } from "zod";

import type { FilterConfig } from "@/components/business/ListFilters/types";
import type { TableColumn } from "@/components/structures/VdTable/types";
import { useUrlState } from "@/composables/use-url-state";
import type { ObjectSerializerSchema } from "@/composables/use-url-state/object-serializer";
import { BRANDING_REPORTING_COLUMNS, CONSIDERATION_REPORTING_COLUMNS, FILTERS_MODES_NUMERICAL, FILTERS_MODES_NUMERICAL_FLOAT, PERFORMANCE_REPORTING_COLUMNS, type REPORTING_COLUMN_KEYS, REPORTING_COLUMNS } from "@/constants";
import {
  numericalField,
  numericalFieldFloat,
  numericDefaultValue,
  numericFloatDefaultValue,
} from "@/utils/filters/numerical-filters";

type DefaultReportingFilterConfig = Record<REPORTING_COLUMN_KEYS, FilterConfig>;

function buildDefaultReportingFiltersConfig<T>(reportingColumns: TableColumn<T, Component | null>[]) {
  return reportingColumns.reduce<DefaultReportingFilterConfig>(
    (acc, column) => {
      const isFloatFilter = column.format?.style === "percent" || column.format?.style === "currency";

      return {
        ...acc,
        [column.id]: {
          id: column.id,
          title: column.label,
          initialValue: isFloatFilter ? numericFloatDefaultValue : numericDefaultValue,
          schema: isFloatFilter ? numericalFieldFloat : numericalField,
          inputComponent: "NumericalFilterInput",
          tagValueComponent: "NumericalFilterTag",
          format: column.format,
          matchModes: isFloatFilter ? FILTERS_MODES_NUMERICAL_FLOAT : FILTERS_MODES_NUMERICAL,
          group: "Metrics",
        } as FilterConfig,
      };
    },
    {} as DefaultReportingFilterConfig,
  );
}

export const DEFAULT_REPORTING_FILTERS_CONFIG = buildDefaultReportingFiltersConfig(REPORTING_COLUMNS);
export const DEFAULT_PERFORMANCE_REPORTING_FILTERS_CONFIG = buildDefaultReportingFiltersConfig(PERFORMANCE_REPORTING_COLUMNS);
export const DEFAULT_BRANDING_REPORTING_FILTERS_CONFIG = buildDefaultReportingFiltersConfig(BRANDING_REPORTING_COLUMNS);
export const DEFAULT_CONSIDERATION_REPORTING_FILTERS_CONFIG = buildDefaultReportingFiltersConfig(CONSIDERATION_REPORTING_COLUMNS);

export type FilterValues<T extends Record<string, FilterConfig>> = { [K in keyof T]: z.infer<T[K]["schema"]["field"]> };

function getConfigInitialValues<T extends Record<string, FilterConfig>>(config: T): FilterValues<T> {
  return Object.values<FilterConfig>(config).reduce(
    (acc, filter) => ({
      ...acc,
      [filter.id]: filter.initialValue,
    }),
    {} as FilterValues<T>,
  );
}

function getConfigSchema<T extends Record<string, FilterConfig>>(config: T): ObjectSerializerSchema<FilterValues<T>> {
  const entries = Object.entries<FilterConfig>(config).map(([key, filter]) => [key, filter.schema]);
  return Object.fromEntries(entries) as ObjectSerializerSchema<FilterValues<T>>;
}

export function useStateFilters<T extends Record<string, FilterConfig>>(filtersConfig: MaybeRefOrGetter<T>) {
  const initialValues = computed(() => getConfigInitialValues<T>(toValue(filtersConfig)));
  const schema = computed(() => getConfigSchema<T>(toValue(filtersConfig)));
  const filters = useUrlState<FilterValues<T>>({ initialValue: initialValues, schema });

  function clear() {
    filters.value = initialValues.value;
  }

  return {
    filters,
    initialValues,
    schema,
    clear,
  };
}
