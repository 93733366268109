/**
 * Utility type that takes an object type and makes the hover overlay more readable.
 * https://www.totaltypescript.com/concepts/the-prettify-helper
 */
export type Prettify<T> = {
  [K in keyof T]: T[K];
} & {};

/**
 * Check if the params is never. Useful for exhaustive checks.
 * @param params
 * throws an error if the params is not never
 */
export function exhaustiveCheck(params: never) {
  // eslint-disable-next-line ts/restrict-template-expressions
  throw new Error(`Unhandled value: ${params} (${typeof params})`);
}

/**
 * Convert union `U` into its intersection. Common trick:
 *    (A|B|C) extends any ? (x:A|B|C)=>void : never
 * becomes  (x:A)=>void & (x:B)=>void & (x:C)=>void,
 * from which we can infer the last parameter.
 */
type UnionToIntersection<U> =
    (U extends unknown ? (x: U) => void : never) extends (x: infer R) => void
      ? R
      : never;

/**
 * Extract the "last" type in a union `U`.
 * This relies on building an intersection of function types and inferring
 * the parameter type in that intersection. The parameter that remains
 * is effectively the last union member in compiler order.
 */
type LastOf<U> =
    UnionToIntersection<
      // Distributive condition to create function types
      U extends unknown ? (x: U) => void : never
    > extends (x: infer R) => void
      ? R
      : never;

type UnionToTuple<U> =
    // If `U` is empty, we are done
    [U] extends [never]
      ? []
      : // Otherwise, compute "last" of the union, then exclude it and recurse
        [...UnionToTuple<Exclude<U, LastOf<U>>>, LastOf<U>];

/**
 * This function is very useful to check that all the cases of a union type are handled.
 * If the function doesn't compile, check your code where this function is called and handle all the cases.
 * Then you can add the new handled type to the list.
 */
export function checkForAllTypesCovered<T extends string>(...value: UnionToTuple<T>) {
  return value;
}
// /* naive approach: */
// export function checkForAllTypesCoveredNaive<T extends string>(value: Record<T, unknown>) {
//   return value;
// }
