<script lang="ts" setup>
import Skeleton from "primevue/skeleton";
import { computed } from "vue";

import { decodeValueForReportingDisplay } from "@/utils/filters/common";
import { prettifyCurrency } from "@/utils/prettifiers/prettify-currency";
import { prettifyNumericalValue } from "@/utils/prettifiers/prettify-numerical-value";
import { prettifyPercent } from "@/utils/prettifiers/prettify-percent";

const props = withDefaults(defineProps<{
  value?: number | null;
  format?: Intl.NumberFormatOptions;
  loading?: boolean;
  inError?: boolean;
}>(), {
  value: null,
});

const formattedValue = computed(() => {
  if (props.value == null) {
    return null;
  }

  if (props.format?.style === "percent") {
    return prettifyPercent(props.value, { format: props.format, displaySign: false });
  }

  const decodedValue = decodeValueForReportingDisplay(props.value);

  if (props.format?.style === "currency") {
    return prettifyCurrency(decodedValue);
  }

  return prettifyNumericalValue(decodedValue);
});
</script>

<template>
  <Skeleton v-if="loading" height="1.5rem" />
  <span v-else-if="inError" v-tooltip.top="'An error occured when loading the statistics'">❌</span>
  <span v-else>{{ formattedValue ?? "-" }}</span>
</template>
