import type { Component, MaybeRef } from "vue";
import { toValue } from "vue";

import AdPlacementCell from "@/components/business/TableCells/campaigns/AdPlacementCell/AdPlacementCell.vue";
import CampaignObjectiveCell from "@/components/business/TableCells/campaigns/CampaignObjectiveCell/CampaignObjectiveCell.vue";
import CampaignStatusCell from "@/components/business/TableCells/campaigns/CampaignStatusCell/CampaignStatusCell.vue";
import CampaignTypeCell from "@/components/business/TableCells/campaigns/CampaignTypeCell/CampaignTypeCell.vue";
import DeviceTypeCell from "@/components/business/TableCells/campaigns/DeviceTypeCell/DeviceTypeCell.vue";
import IsTestModeCell from "@/components/business/TableCells/campaigns/IsTestModeCell/IsTestModeCell.vue";
import NameCell from "@/components/business/TableCells/campaigns/NameCell/NameCell.vue";
import TodaySpendCell from "@/components/business/TableCells/campaigns/TodaySpendCell/TodaySpendCell.vue";
import AppCell from "@/components/business/TableCells/common/AppCell/AppCell.vue";
import CountriesCell from "@/components/business/TableCells/common/CountriesCell/CountriesCell.vue";
import { generateReportingColumn } from "@/components/business/TableCells/common/ReportingCell/generate-reporting-cell";
import ReportingCell from "@/components/business/TableCells/common/ReportingCell/ReportingCell.vue";
import type { TableColumn } from "@/components/structures/VdTable/types";
import { createTableColumn } from "@/components/structures/VdTable/types";
import usePermissions from "@/composables/use-permissions";
import usePersistentColumns from "@/composables/use-persistent-columns";
import type { CampaignObjective } from "@/constants";
import { REPORTING_COLUMNS } from "@/constants";
import links from "@/router/links";
import type { SoftIntersection } from "@/types/helpers";
import { sortCampaignApp } from "@/utils/sort-campaign-app";
import { sortCampaignIsTestMode } from "@/utils/sort-campaign-is-test-mode";
import { sortCampaignStatus } from "@/utils/sort-campaign-status";

import { CAMPAIGNS_LIST_PAGE_ID } from "./constants";
import type { CampaignsListPageAwarenessCampaignRow, CampaignsListPageConversionCampaignRow } from "./types";

type CampaignsListPageAllFields = SoftIntersection<CampaignsListPageConversionCampaignRow, CampaignsListPageAwarenessCampaignRow> & { objective: CampaignObjective }; // TODO
export type CampaignsListPageColumns = TableColumn<CampaignsListPageAllFields, Component | null>[];
/**
 * Columns order customization
 */
export function useColumns({
  isReportsLoading,
  isReportsError,
}: {
  isReportsLoading: MaybeRef<boolean>;
  isReportsError: MaybeRef<boolean>;
}) {
  const { hasCampaignTestModeWritePermission } = usePermissions();

  const testModeColumn = hasCampaignTestModeWritePermission.value
    ? [
        createTableColumn<CampaignsListPageAllFields>()({
          id: "isTestMode",
          label: "Test Mode",
          maxWidth: 120,
          minWidth: 120,
          displayedByDefault: true,
          component: IsTestModeCell,
          componentProps: ({ row }) => ({ isTestMode: row.isTestMode }),
          isSortable: true,
          sortingFn: sortCampaignIsTestMode,
          isCentered: true,
        }),
      ]
    : [];

  const customizableColumns = [
    createTableColumn<CampaignsListPageAllFields>()(
      {
        id: "name",
        label: "Name",
        minWidth: 300,
        component: NameCell,
        componentProps: ({ row }) => ({
          campaignId: row.id,
          name: row.name,
          link: links.campaigns.details.ads,
        }),
        isPinnedLeft: true,
        isSortable: true,
      },
    ),

    ...testModeColumn,

    createTableColumn<CampaignsListPageAllFields>()({
      id: "objective",
      label: "Objective",
      component: CampaignObjectiveCell,
      componentProps: ({ row }) => ({
        campaignOjective: row.objective,
      }),
      isSortable: true,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "app",
      label: "App",
      displayedByDefault: true,
      component: AppCell,
      minWidth: 250,
      isSortable: true,
      componentProps: ({ row }) => ({
        app: row.app ?? null,
        appId: row.appId ?? null,
      }),
      sortingFn: sortCampaignApp,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "campaignType",
      label: "Type",
      displayedByDefault: true,
      component: CampaignTypeCell,
      isSortable: true,
      componentProps: ({ row }) => ({
        campaignType: row.type ?? null,
        roasType: row.roasType ?? null,
      }),
      minWidth: 100,
      maxWidth: 100,
      isCentered: true,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "deviceType",
      label: "Device Type",
      component: DeviceTypeCell,
      isSortable: true,
      componentProps: ({ row }) => ({ deviceType: row.deviceType }),
      isCentered: true,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "countries",
      label: "Countries",
      displayedByDefault: true,
      component: CountriesCell,
      componentProps: ({ row }) => ({
        countries: row.countries,
        showLabel: false,
      }),
      isCentered: true,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "status",
      label: "Status",
      displayedByDefault: true,
      component: CampaignStatusCell,
      componentProps: ({ row }) => ({ status: row.status }),
      isSortable: true,
      sortingFn: sortCampaignStatus,
      minWidth: 100,
      maxWidth: 100,
      isCentered: true,
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "todaySpendProgression",
      label: "Today's spend",
      displayedByDefault: true,
      component: TodaySpendCell,
      isSortable: true,
      componentProps: ({ row }) => ({
        dailyBudget: row.dailyBudget,
        todaySpend: row.todaySpend,
        todaySpendProgression: row.todaySpendProgression,
      }),
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "dailyBudget",
      label: "Daily budget",
      displayedByDefault: true,
      format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
      minWidth: 160,
      isSortable: true,
      component: ReportingCell,
      componentProps: ({ row }) => ({
        format: { style: "currency", currency: "USD", maximumFractionDigits: 2 },
        value: row.dailyBudget,
        loading: toValue(isReportsLoading),
        inError: toValue(isReportsError),
      }),
    }),

    createTableColumn<CampaignsListPageAllFields>()({
      id: "adPlacement",
      label: "Placement",
      displayedByDefault: true,
      component: AdPlacementCell,
      isSortable: true,
      componentProps: ({ row }) => ({ adPlacement: row.adPlacement ?? null }),
      isCentered: true,
    }),

    ...REPORTING_COLUMNS.map(column => createTableColumn<CampaignsListPageAllFields>()(
      generateReportingColumn<CampaignsListPageAllFields>({
        column,
        isReportsError,
        isReportsLoading,
      }),
    )),
  ] satisfies CampaignsListPageColumns;

  const { selectedColumns } = usePersistentColumns(
    `${CAMPAIGNS_LIST_PAGE_ID}-columns`,
    customizableColumns,
  );

  return {
    selectedColumns,
    customizableColumns,
  };
}
