import { useRouteQuery } from "@vueuse/router";
import { computed, type MaybeRefOrGetter, type Ref, toValue } from "vue";
import { useRoute, useRouter } from "vue-router";

type UrlStorage<T> = { [K in keyof T]: Ref<string | string[] | null | undefined> };

// eslint-disable-next-line ts/no-explicit-any
export type UrlStorageValue<T = any> = { [K in keyof T]: string | string[] };

type RouteQueryValueRaw = string | string[] | null;

/**
 * Function that will help manage the state of the URL.
 */
export function createUrlStorage<T>(fields: MaybeRefOrGetter<Array<keyof T>>, defaultValues: MaybeRefOrGetter<Record<keyof T, RouteQueryValueRaw>>) {
  const route = useRoute();
  const router = useRouter();

  const urlStorage = computed(() => {
    const defaultVals = toValue(defaultValues);
    return toValue(fields).reduce((acc, fieldName) => {
      const defaultValue = defaultVals[fieldName as unknown as keyof T];
      const result = {
        ...acc,
        [fieldName as unknown as keyof T]: useRouteQuery(fieldName as unknown as string, defaultValue, { route, router }),
      };

      return result;
    }, {} as UrlStorage<T>);
  });

  function get(): UrlStorageValue<T> {
    return toValue(fields).reduce((acc, fieldName) => {
      const key = fieldName as unknown as keyof T;
      if (urlStorage.value[key].value != null) {
        return { ...acc, [key]: urlStorage.value[key].value };
      }
      return acc;
    }, {} as { [K in keyof T]: string | string[] });
  }

  function set(obj: UrlStorageValue<T> | null): void {
    const globalFields = toValue(fields);
    if (globalFields.length === 0) {
      throw new Error("unexpected error");
    }

    for (const fieldName of globalFields) {
      const key = fieldName as unknown as keyof T;
      const defaultValue = toValue(defaultValues)[key];
      let valueToStore = null;
      /**
       * If the value is null or empty, we store the default value.
       */
      if (obj != null && Object.prototype.hasOwnProperty.call(obj, key)) {
        valueToStore = obj[key] == null || obj[key] === "" ? defaultValue : obj[key];
      }

      /**
       * for useRouteQuery to handle the case where the value is null or empty,
       * we need to set the value to undefined.
       */
      urlStorage.value[key].value = valueToStore ?? undefined;
    }
  }

  function deleteParam(paramName: keyof T): void {
    /**
     * By passing the default value to the URL value,
     * we let useRouteQuery remove the value from the URL.
     */
    urlStorage.value[paramName].value = toValue(defaultValues)[paramName];
  }

  return {
    get,
    set,
    deleteParam,
  };
}
