import type { RouteComponent, RouteRecordRaw } from "vue-router";

import AdTestAppCheckPage from "@/pages/ads/AdTestAppCheckPage/AdTestAppCheckPage.vue";
import CampaignsListPage from "@/pages/campaigns/CampaignsList/CampaignsListPage.vue";

import links from "./links";

function AdCreationPage(): RouteComponent {
  return import("@/pages/ads/AdCreation/AdCreationPage.vue");
}

function NativeAdCreationPage(): RouteComponent {
  return import("@/pages/ads/NativeAdCreation/NativeAdCreationPage.vue");
}

function CampaignCreationPage(): RouteComponent {
  return import("@/pages/campaigns/CampaignCreation/CampaignCreationPage.vue");
}

function CampaignDetailsAdsPage(): RouteComponent {
  return import(
    "@/pages/campaigns/campaign-details/CampaignDetailsAds/CampaignDetailsAdsPage.vue"
  );
}

function CampaignDetailsCountriesPage(): RouteComponent {
  return import(
    "@/pages/campaigns/campaign-details/CampaignDetailsCountries/CampaignDetailsCountriesPage.vue"
  );
}

function CampaignDetailsPage(): RouteComponent {
  return import("@/pages/campaigns/campaign-details/CampaignDetailsPage.vue");
}

function CampaignDetailsSourcesPage(): RouteComponent {
  return import(
    "@/pages/campaigns/campaign-details/CampaignDetailsSources/CampaignDetailsSourcesPage.vue"
  );
}

function CampaignEditPage(): RouteComponent {
  return import("@/pages/campaigns/CampaignEdit/CampaignEditPage.vue");
}

export const campaignRoutes: RouteRecordRaw[] = [
  {
    path: ":campaignObjective",
    component: CampaignsListPage,
    name: links.campaigns.listByObjective,
    props: true,
    meta: {
      title: "Campaigns",
    },
  },
  {
    path: "",
    component: CampaignsListPage,
    name: links.campaigns.list,
    props: true,
    meta: {
      title: "Campaigns",
    },
  },
  {
    path: "new/:campaignObjective?",
    component: CampaignCreationPage,
    props: true,
    name: links.campaigns.new,
    meta: {
      title: "New campaign",
    },
  },
  {
    path: ":duplicateCampaignId/duplicate/:allowAdsDuplication?",
    component: CampaignCreationPage,
    props: true,
    name: links.campaigns.duplicate,
    meta: {
      title: "Duplicate the campaign",
    },
  },
  {
    path: ":campaignId",
    redirect: { name: links.campaigns.details.ads },
    props: true,
    children: [
      {
        path: "ads/new",
        component: AdCreationPage,
        name: links.campaigns.ads.newAd,
        props: true,
        meta: {
          title: "New  ad",
        },
      },
      {
        path: "native-ads/new",
        component: NativeAdCreationPage,
        name: links.campaigns.ads.newNativeAd,
        props: true,
        meta: {
          title: "New native ad",
        },
      },
      {
        path: "edit",
        component: CampaignEditPage,
        name: links.campaigns.edit,
        props: true,
        meta: {
          title: "Campaign edition",
        },
      },
      {
        path: "ads/:adId/qr-code-check",
        component: AdTestAppCheckPage,
        name: links.campaigns.ads.adTestAppCheck,
        props: true,
        meta: {
          title: "QR code check",
        },
      },
      {
        path: "details",
        component: CampaignDetailsPage,
        props: true,
        children: [
          {
            path: "",
            redirect: { name: links.campaigns.details.ads },
          },
          {
            path: "ads/:adId?",
            component: CampaignDetailsAdsPage,
            name: links.campaigns.details.ads,
            props: true,
            meta: {
              title: "Campaign ADs",
            },
          },
          {
            path: "countries",
            component: CampaignDetailsCountriesPage,
            name: links.campaigns.details.countries,
            props: true,
            meta: {
              title: "Campaign countries",
            },
          },
          {
            path: "sources",
            component: CampaignDetailsSourcesPage,
            name: links.campaigns.details.sources,
            props: true,
            meta: {
              title: "Campaign sources",
            },
          },

        ],
      },
    ],
  },
];
