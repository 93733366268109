<script setup lang="ts" generic="T extends { [key: string]: unknown }, U extends TableColumn<any, Component | null>[]">
import type { Column, ColumnDef, Row, RowSelectionState, Table } from "@tanstack/vue-table";
import { FlexRender, getCoreRowModel, getSortedRowModel, useVueTable } from "@tanstack/vue-table";
import { useVirtualizer } from "@tanstack/vue-virtual";
import { useElementSize, useWindowSize } from "@vueuse/core";
import ProgressSpinner from "primevue/progressspinner";
import { type Component, computed, type CSSProperties, h, ref, useSlots, watch } from "vue";

import SelectionCell from "@/components/business/TableCells/common/SelectionCell/SelectionCell.vue";
import { useHorizontalScrollBoundaries } from "@/composables/use-horizontal-scroll-boundaries";

import VdEmpty from "../VdEmpty/VdEmpty.vue";
import type { TableColumn } from "./types";
import { useTable } from "./use-table";

const props = withDefaults(defineProps<{
  data: T[];
  columns: U;
  height?: number;
  rowHeight?: number;
  isSelectable?: boolean;
  selection?: RowSelectionState;
  isInfiniteLoader?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  isSelectAllEnabled?: boolean;
  isSelectAllTooltipText?: string;
  rowClass?: string;
  isLoading?: boolean;
  totalRowsCount?: number | null;
}>(), {
  rowClass: "px-3 py-2",
  rowHeight: 50,
  isInfiniteLoader: false,
  hasNextPage: false,
  totalRowsCount: null,
  isFetchingNextPage: false,
  isSelectAllEnabled: true,
  isLoading: false,
});

const emit = defineEmits<{
  "row-selection-change": [value: RowSelectionState];
  "load-more": [];
}>();

const MIN_ROWS_LENGTH = 30;
const MIN_LIST_HEIGHT = 500;
const ACTIONS_COLUMN_WIDTH = 42;
const MIN_LIST_WIDTH = 400;
const MIN_COLUMN_WIDTH = 150;

const rowSelection = ref<RowSelectionState>({});

watch(() => props.selection, (value) => {
  if (value) {
    rowSelection.value = value;
  }
}, { immediate: true, deep: true });

const computedColumns = computed(() => props.columns);

const { columnDefs } = useTable({ columns: computedColumns, isLoading: () => props.isLoading });

const columnsWithSelection = computed<ColumnDef<T, unknown>[]>(() => {
  if (!props.isSelectable) {
    return columnDefs.value as ColumnDef<T, unknown>[];
  }

  return [
    {
      id: "select",
      header: ({ table }: { table: Table<T> }) => {
        return h(
          SelectionCell,
          {
            value: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
            disabled: !props.isSelectAllEnabled,
            tooltipText: props.isSelectAllTooltipText,
          },
        );
      },
      cell: ({ row }: { row: Row<T> }) => {
        return h(
          SelectionCell,
          {
            value: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            onChange: row.getToggleSelectedHandler(),
          },
        );
      },
      meta: {
        isPinnedLeft: true,
      },
      minSize: 40,
      maxSize: 40,
    },

    ...(columnDefs.value as ColumnDef<T, unknown>[]),
  ];
});

type MetaType = {
  isPinnedLeft?: boolean;
  isPinnedRight?: boolean;
  isCentered?: boolean;
};

const tableContainerRef = ref();
const tableRef = ref();

const { height: tableRefHeight } = useElementSize(tableRef);
const { width: containerWidth } = useElementSize(tableContainerRef);
const { height: windowInnerHeight } = useWindowSize();

const pinnedLeftColumnsIds = computed(() => {
  let pinnedColumnsTotalWidth = 0;
  /** Handles pinned columns order */
  let hasReachedMax = false;

  return columnsWithSelection.value.reduce((acc, column) => {
    if (column.id && (column.meta as MetaType)?.isPinnedLeft) {
      const minSize = column.minSize ?? MIN_COLUMN_WIDTH;

      if ((pinnedColumnsTotalWidth + minSize + MIN_LIST_WIDTH > containerWidth.value) || hasReachedMax) {
        hasReachedMax = true;
        return acc;
      }

      acc.push(column.id);
      pinnedColumnsTotalWidth += minSize;
    }

    return acc;
  }, [] as string[]);
});

function getCommonPinningStyles(column: Column<T>): CSSProperties {
  const isPinned = column.getIsPinned();

  return {
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right: isPinned === "right" ? `${column.getAfter("right")}px` : 0,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 10 : 0,
  };
}

function onRowSelectionChange(updateOrValue: RowSelectionState | ((prev: RowSelectionState) => RowSelectionState)) {
  rowSelection.value = typeof updateOrValue === "function"
    ? updateOrValue(rowSelection.value)
    : updateOrValue;

  emit("row-selection-change", rowSelection.value);
}

const skeletonData = computed(() => {
  const rowData = Object.fromEntries(props.columns.map(({ id }) => [id, null])) as T;

  return Array.from({ length: 10 }, () => rowData);
});

const data = computed(() => props.isLoading ? skeletonData.value : props.data);

const options = computed(() => ({
  data: data.value,
  columns: columnsWithSelection.value,
  state: {
    rowSelection: rowSelection.value,
    columnPinning: {
      left: pinnedLeftColumnsIds.value,
      right: [],
    },
  },
  getCoreRowModel: getCoreRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getRowId: (row: T) => row.id as string,
  enableRowSelection: true,
  onRowSelectionChange,
}));

const table = computed(() => useVueTable(options.value));
const slots = useSlots();

const rows = computed(() => {
  return table.value.getRowModel().rows;
});

const isVirtualTable = computed(() => {
  return rows.value.length > MIN_ROWS_LENGTH;
});

const rowVirtualizerOptions = computed(() => {
  return {
    count: rows.value.length,
    getScrollElement: () => tableContainerRef.value,
    estimateSize: () => props.rowHeight,
    overscan: 20,
  };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());

const { hasScrollReachedRight, hasScrollReachedLeft } = useHorizontalScrollBoundaries(tableContainerRef);

function calculateColumnWidth(column: Column<T, unknown>) {
  const allColumns = table.value.getAllFlatColumns();

  /** We need to calculate the width of all columns + actions column */
  const columnsWidth = allColumns.reduce((acc, column) => {
    /** If the column has a max size, we need to respect it */
    if (column.columnDef.maxSize) {
      return acc + column.columnDef.maxSize;
    }

    return acc + (column.columnDef.minSize ?? column.getSize());
  }, 0) + (slots.actions ? ACTIONS_COLUMN_WIDTH : 0);

  const tableWidth = tableContainerRef.value?.offsetWidth;

  /** Calculate the number of columns that have a max size */
  const maxSizeColumnsLength = allColumns.filter(column => column.columnDef.maxSize).length;

  /** Calculate the number of columns that don't have a max size, as they shouldn't be considered in the calculation */
  const columnsLength = allColumns.length - maxSizeColumnsLength;

  /** Calculate the remaining width */
  const remainingWidth = tableWidth - columnsWidth;

  /** If there is no remaining width, we can just return the column size */
  if (remainingWidth <= 0) {
    return column.getSize();
  }

  const delta = remainingWidth / columnsLength;

  /** If the column has a max size, we need to respect it */
  if (column.columnDef.maxSize) {
    return column.columnDef.maxSize;
  }

  /** If the column has a minSize, we need to respect it */
  if (column.columnDef.minSize) {
    return column.columnDef.minSize + delta;
  }

  /** Otherwise, we can just add the delta to the column size */
  return column.getSize() + delta;
}

function fetchMoreOnBottomReached() {
  if (!props.isInfiniteLoader) {
    return;
  }

  const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.value;

  if (scrollHeight - scrollTop - clientHeight < 800) {
    emit("load-more");
  }
}

const tableHeight = computed<string>(() => {
  if (isVirtualTable.value === false) {
    return "auto";
  }

  if (props.height) {
    return `${props.height}px`;
  }

  const listEstimatedHeight = windowInnerHeight.value - (tableContainerRef.value?.getBoundingClientRect().top ?? 0);

  if (tableRefHeight.value < listEstimatedHeight) {
    /* 2px handles the border */
    return `${tableRefHeight.value + 2}px`;
  }

  if (listEstimatedHeight < MIN_LIST_HEIGHT) {
    const totalHeight = tableRefHeight.value > MIN_LIST_HEIGHT ? MIN_LIST_HEIGHT : (tableRefHeight.value + 2);
    return `${totalHeight}px`;
  }

  const listHeight = listEstimatedHeight > tableRefHeight.value ? tableRefHeight.value : listEstimatedHeight - 80;

  return `${listHeight}px`;
});
</script>

<template>
  <div class="flex w-full flex-col gap-3">
    <slot name="header" />

    <div
      v-if="totalRowsCount === 0 && !isLoading"
    >
      <slot name="nodata">
        <VdEmpty title="no data">
          <p class="text-color-secondary">
            No data here.
          </p>
        </VdEmpty>
      </slot>
    </div>

    <div
      v-else-if="!isLoading && rows.length === 0"
    >
      <slot name="empty">
        <VdEmpty title="no data">
          <p class="text-color-secondary">
            No data matching your query.
          </p>
        </VdEmpty>
      </slot>
    </div>

    <div
      v-else
      ref="tableContainerRef"
      class="relative w-full overflow-auto"
      :style="{ height: tableHeight }"
      @scroll="fetchMoreOnBottomReached"
    >
      <table ref="tableRef" class="w-full" cellpadding="0" cellspacing="0">
        <thead class="sticky top-0 z-20 bg-white">
          <tr
            v-for="headerGroup in table.getHeaderGroups()"
            :key="headerGroup.id"
            class="flex w-full"
          >
            <th
              v-for="header in headerGroup.headers"
              :key="header.id"
              :colSpan="header.colSpan"
              class="flex items-center border-0 border-y border-solid border-primary-100 bg-white p-3 text-left"
              :style="{ ...getCommonPinningStyles(header.column), width: `${calculateColumnWidth(header.column)}px` }"
              :class="{
                'justify-center': (header.column.columnDef.meta as MetaType)?.isCentered,
                'column-left-raised': header.column.getIsPinned() === 'left' && hasScrollReachedLeft === false,
              }"
            >
              <div class="whitespace-nowrap text-left">
                <FlexRender
                  v-if="!header.isPlaceholder"
                  :render="header.column.columnDef.header"
                  :props="header.getContext()"
                />
              </div>
            </th>

            <th
              v-if="slots.actions"
              key="actions-button"
              class="sticky right-0 z-10 border-0 border-y border-solid border-primary-100 bg-white"
              :class="{
                'column-right-raised': hasScrollReachedRight === false,
              }"
              :style="{ width: `${ACTIONS_COLUMN_WIDTH}px` }"
            />
          </tr>
        </thead>

        <tbody class="relative" :class="{ grid: isVirtualTable }" :style="isVirtualTable ? { height: `${rowVirtualizer.getTotalSize()}px` } : {}">
          <tr
            v-for="row in virtualRows"
            :key="(row.key as string)"
            class="vd-virtual-table-row flex w-full"
            :class="{
              absolute: isVirtualTable,
            }"
            :style="isVirtualTable ? { transform: `translateY(${row.start}px)` } : { height: `${rowHeight}px` }"
          >
            <td
              v-for="cell in rows[row.index]?.getVisibleCells()"
              :key="cell.id"
              :style="{ ...getCommonPinningStyles(cell.column), width: `${calculateColumnWidth(cell.column)}px` }"
              class="vd-virtual-table-cell flex items-center border-0 border-b border-solid border-primary-100"
              :class="[
                rowClass,
                {
                  'justify-center': (cell.column.columnDef.meta as MetaType)?.isCentered,
                  'bg-primary-50': row.index % 2,
                  'bg-white': !(row.index % 2),
                  'column-left-raised': cell.column.getIsPinned() === 'left' && hasScrollReachedLeft === false,
                  'text-slate-400': rows[row.index]?.original?.isArchived,
                }]"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </td>

            <td
              v-if="slots.actions"
              class="vd-virtual-table-cell sticky right-0 z-10 flex items-center border-0 border-b border-solid border-primary-100"
              :class="{
                'bg-primary-50': row.index % 2,
                'bg-white': !(row.index % 2),
                'column-right-raised': hasScrollReachedRight === false,
              }"
              :style="{ width: `42px` }"
            >
              <slot name="actions" :row="rows[row.index]?.original" />
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="isInfiniteLoader && hasNextPage"
        class="sticky bottom-2 z-10 flex w-full justify-center transition-opacity"
        :class="{
          'opacity-0': !isFetchingNextPage,
          'opacity-1': isFetchingNextPage,
        }"
      >
        <div class="white-box flex items-center gap-2 rounded-full px-4 py-3">
          <ProgressSpinner
            stroke-width="4"
            :style="{ width: '12px', height: '12px' }"
          />

          <span class="text-gray-800">Loading data</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* Handle frozen columns */
.column-left-raised:after {
  right: -11px;
  box-shadow: inset 10px 0 10px -10px rgba(var(--primary-color-rgb), 0.3);
}

.column-right-raised:after {
  left: -11px;
  box-shadow: inset -10px 0 10px -10px rgba(var(--primary-color-rgb), 0.3);
}

.column-left-raised:after,
.column-right-raised:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 10px;
}
</style>
